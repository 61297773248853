<template>
  <section-quit-header class="h-0" />
  <dialog-video v-if="isVideoOpen" :key="key" :url="home_meta.video.url" />

  <div class="relative flex w-full min-h-[31.25rem] md:min-h-[40.5rem] font-roboto">
    <picture>
      <source :srcset="home_meta.image1.desktop.url" media="(orientation: landscape)" />
      <img class="absolute inset-0 w-full h-full object-cover object-[50%_75%]" :src="home_meta.image1.mobile.url" />
    </picture>

    <div class="relative flex-none flex flex-col w-full">
      <div class="flex-none my-auto pt-20 pb-12 lg:pt-26 lg:pb-24">
        <div class="brace font-degular font-bold">
          <p class="max-w-[280px] md:max-w-[512px] mb-4.5 text-lg/[1.15] md:text-2xl/[1.15]">{{home_meta.hero.title}}</p>

          <p class="max-w-[268px] md:max-w-[580px] uppercase text-green text-[2.5rem]/[1] md:text-[4rem]/[1]">
            <strong class="font-extrabold">{{home_meta.hero.body}} </strong>
          </p>
          <div class="flex  mt-4" v-if="home_meta.video.url">
            <button class="bg-green p-[0.85rem] rounded-full w-min me-5" type="button" @click="openCtaVideo">
              <icon-play class="w-[0.65rem] h-[0.65rem] text-white" :font-controlled="false" />
            </button>
            <button class="btn btn--outline" type="button" @click="openCtaVideo">
              {{ home_meta.video.title }}
            </button>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="brace relative text-center">
    <arrow-down class="inline-block text-green !w-[24px] !h-[110px] -mt-[55px]" />
  </div>

  <div class="brace mt-11 mb-8 lg:mt-8 lg:mb-14">
    <h2 class="max-w-[28em] mb-6 lg:md-5 mx-auto text-2xl/[1.3] lg:text-3.5xl/[1.3] font-bold text-center uppercase">
      {{ home_meta.main.title }}
    </h2>

    <div class="max-w-[48em] mx-auto px-4 text-[1rem] text-center">
      {{ home_meta.main.body }}
    </div>
  </div>

  <div class="brace flex max-lg:flex-col gap-6 md:mb-5 lg:mb-11 font-roboto">
    <card-home class="bg-[#278AC6]"
               :title="t('cards.onlineTitle')"
               :body="home_meta.cards.onlineBody"
               :link="locale == 'fr' ? '/jarrete/inscription' : '/iquitnow/registration'" external
               :button="t('cards.onlineButton')">
      <template v-slot:icon>
        <icon-desktop class="w-[5rem] h-[5rem]" :font-controlled="false" />
      </template>
    </card-home>

    <card-home class="bg-green"
               :title="t('cards.cellTitle')"
               :body="home_meta.cards.cellBody"
               :link="localePath('quit-subpage-index-help-phone')"
               :button="t('cards.cellButton')">
      <template v-slot:icon>
        <icon-phone class="w-[5rem] h-[5rem]" :font-controlled="false" />
      </template>
    </card-home>

    <card-home class="bg-[#0D5EA7]"
               :title="t('cards.personTitle')"
               :body="home_meta.cards.personBody"
               :link="localePath('quit-subpage-index-help-person')"
               :button="t('cards.personButton')">
      <template v-slot:icon>
        <icon-person class="w-[5rem] h-[5rem]" :font-controlled="false" />
      </template>
    </card-home>
  </div>

  <section-facebook :content="home_meta.facebook"/>

  <div class="brace max-md:p-0 md:flex md:mt-11 md:mb-4 gap-4 lg:gap-10 font-roboto">
    <div class="flex-1 basis-[42.857%] md:w-0 md:flex">
      <img class="flex-none w-full h-[455px] my-auto object-cover md:rounded-2xl md:w-[600px] md:h-full" :src="width < 860 ? home_meta.image2.mobile.url : home_meta.image2.desktop.url" />
    </div>

    <div class="flex-1 basis-[54.285%] md:w-0 md:flex flex-col px-[32px] py-[74px] md:rounded-2xl md:p-[64px] xl:p-[80px] bg-white">
      <div class="flex-none my-auto">
        <h2 class="mb-8 text-2xl md:text-3.5xl font-bold font-degular">{{ home_meta.preparation.title }}</h2>
        <p class="mb-3 max-md:text-sm">{{ home_meta.preparation.body1 }}</p>
        <p class="mb-3 max-md:text-sm">{{ home_meta.preparation.body2 }}</p>

        <div class="mt-10 pb-3">
          <nuxt-link class="btn btn--outline" :to="localePath('quit-subpage-index-hesitate-index')">{{ home_meta.preparation.btn }}</nuxt-link>
        </div>
      </div>
    </div>
  </div>

  <div class="md:mt-[100px] max-md:pt-[42px]">
    <div class="max-md:border-t max-md:border-t-green">
      <div class="brace font-roboto">
        <div class="py-12 md:py-14 md:border-t md:border-t-green">
          <div class="max-w-[900px] mx-auto flex max-md:flex-col gap-14 md:gap-8">
            <div class="flex-1 my-auto text-center">
              <a target="_blank" href="https://cqts.qc.ca/"><img class="inline-block max-md:w-[172px]" width="131" height="80" src="/images/homepage/cqts.svg" /></a>
            </div>
            <div class="flex-1 my-auto text-center">
              <a target="_blank" href="https://www.quebec.ca/"><img class="inline-block" width="189" height="68" src="/images/homepage/quebec.svg" /></a>
            </div>
            <div class="flex-1 my-auto text-center">
              <a target="_blank" href="https://cancer.ca/"><img class="inline-block" width="186" height="62" src="/images/homepage/ccs.svg" /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardQuitCtaHome from "~/components/Cards/CardQuitCtaHome.vue";
import CardHome from "~/components/Cards/CardHome.vue"
import HeaderHome from "~/components/Headers/HeaderHome.vue";
import SectionNews from "~/components/Sections/SectionNews.vue";
import SectionPagePreview from "~/components/Sections/SectionPagePreview.vue";
import SectionFacebook from "~/components/Sections/SectionFacebook.vue";
import SectionQuitHeader from "~/components/Sections/SectionQuitHeader.vue";

import ArrowDown from "~/assets/svg/arrow-down.svg";
import IconPerson from "~/assets/svg/icon_person.svg";
import IconDesktop from "~/assets/svg/icon_desktop.svg";
import IconPhone from "~/assets/svg/icon_phone.svg";
import IconPlay from "~/assets/svg/play.svg";
import DialogVideo from "~/components/Dialog/DialogVideo.vue";

import { setupMeta } from "~/utils/utils";
import { useWindowSize } from '@vueuse/core'

export default {
  name: "PageQuitIndex",
  async setup() {
    defineI18nRoute({
      locales: ["fr", "en"],
      paths: {
        fr: "/jarrete",
        en: "/iquitnow",
      },
    });
    const { t, locale } = useI18n({
      useScope: "local",
    });
    const localePath = useLocalePath();
    const route = useRoute();
    const nuxtApp = useNuxtApp();

    const { information, prevention, quit, latest_posts, home_meta } = nuxtApp.$store.state.web;
    const { width } = useWindowSize()

    // if (!quit.hesitate || !quit.help || !quit.pro) {
    //   await store.dispatch("quit/fetchIndex");
    // }
    await nuxtApp.runWithContext(() => setupMeta(route.query.path, quit.seo));

    return {
      t,
      locale,
      localePath,
      information,
      prevention,
      quit,
      posts: latest_posts,
      home_meta,
      width
    };
  },
  data() {
    return {
      isVideoOpen: false,
      key: 0
    };
  },
  methods: {
    openCtaVideo() {
      this.isVideoOpen = true;
      this.key += 1;
    }
  },
  mounted() {
    document.addEventListener("keydown", (e) => {
      if (e.code === 27 && this.is_drawer_open) this.is_drawer_open = false;
    });
  },
  components: {
    ArrowDown,
    CardHome,
    CardQuitCtaHome,
    HeaderHome,
    IconDesktop,
    IconPerson,
    IconPhone,
    IconPlay,
    DialogVideo,
    SectionFacebook,
    SectionQuitHeader,
    SectionNews,
    SectionPagePreview,
  },
};
</script>

<style lang="scss">
  html {
    @apply font-roboto;
  }
</style>

<i18n lang="json">
{
  "fr": {
    "cards": {
      "onlineTitle": "EN LIGNE",
      "onlineButton": "Je m'inscris",
      "personTitle": "EN PERSONNE",
      "personButton": "Je prends RDV",
      "cellTitle": "PAR TÉLÉPHONE",
      "cellButton": "J'appelle"
    }
  },
  "en": {
    "cards": {
      "onlineTitle": "ONLINE",
      "onlineButton": "Sign up",
      "personTitle": "IN PERSON",
      "personButton": "Book an appointment",
      "cellTitle": "BY PHONE",
      "cellButton": "Call now"
    }
  }
}
</i18n>
